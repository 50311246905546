import axios from "axios"

const API_KEY = process.env.VUE_APP_FUSION_API_KEY;
const url = `${process.env.VUE_APP_FUSION_API_URL}/companies?API-KEY=${API_KEY}`;
const snapURL = `${process.env.VUE_APP_FUSION_API_URL}/companies/snap?API-KEY=${API_KEY}`;


export default {
  async getCompanies() {    
    let res = await axios.get(`${url}`);    
    return res.data.data;
  },
  async getSnapCompanies() {
    let res = await axios.get(`${snapURL}`);    
    return res.data.data;
  },
  async getCompany(companyId) {    
    let res = await axios.get(`${url}&id=${companyId}`);    
    return res.data.data[0];
  }
}